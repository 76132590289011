import React from "react";
import {
  SectionContainer,
  Question,
  QuestionTitle,
  QuestionText,
} from "../styles/components/Section5Styles";

const Section5 = () => {
  return (
    <>
      <h2 style={{paddingTop: '12px', backgroundColor: "#1a1a1a", color: "#fff", textAlign: "center" }}>Perguntas Frequentes</h2>
      <SectionContainer>
        <Question>
          <QuestionTitle>
            Tenho alguma garantia ao contratar a DRS3?
          </QuestionTitle>
          <QuestionText>
            Acreditamos tanto em nossos serviços que oferecemos a você uma
            garantia única. Se, por algum motivo incomum, você não estiver
            satisfeito com nossa assessoria de marketing, você pode cancelar
            quando quiser sem pagar um centavo pela rescisão contratual, desde
            que cumprido o aviso prévio. Nós sempre priorizamos a sua
            satisfação.
          </QuestionText>
        </Question>

        <Question>
          <QuestionTitle>
          Já investi muito em outras agências e não tive resultados. Tenho medo de tentar novamente e não dar certo.
          </QuestionTitle>
          <QuestionText>
            A DRS3 não é uma agência, ela é uma aceleradora digital. A nossa
            principal diferença está no acompanhamento ao cliente, entendendo o
            seu produto/serviço, diferenciais, dificuldades, problemas e
            trazendo soluções para que ele venda mais através da Internet. É uma
            via de mão dupla. A DRS3 só cresce se o seu negócio crescer também.
          </QuestionText>
        </Question>

        <Question>
          <QuestionTitle>
            Depois de preencher o formulário, em quanto tempo a equipe da DRS3
            entrará em contato?
          </QuestionTitle>
          <QuestionText>
            Após o preenchimento do formulário, o nosso time entrará em contato
            em até 12h úteis.
          </QuestionText>
        </Question>
      </SectionContainer>
    </>
  );
};

export default Section5;
