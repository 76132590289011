import styled from 'styled-components';

export const SectionContainer = styled.section`
  padding: 50px 0;
  background-color: #333333;
  text-align: center;
  color: #fff;
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SectionSubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fc9404;

  >label{
    font-weight: bolder;
    font-size: 1.8rem;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const Card = styled.div`
  width: 250px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #000000;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
`;

export const CardContent = styled.p`
  font-size: 1.1rem;
  color: #ccc;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #fc9404;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adiciona uma transição suave para a cor de fundo e transformação (escala) */
  width: 250px;
  &:hover {
    background-color: #ffaa22; /* Altera a cor de fundo ao passar o mouse */
    transform: scale(1.07); /* Aumenta a escala em 7% */
  }
`;
