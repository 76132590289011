// HeaderStyles.js

import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff; /* Cor de fundo do cabeçalho */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Sombra */
  z-index: 1000;
  height: 6rem;
  padding: 0 6.6rem; /* Ajusta o espaçamento nas laterais para telas menores */
  background-color: #000;

  @media (max-width: 768px) {
    padding: 0 1.5rem; /* Ajusta o espaçamento nas laterais para telas muito pequenas */
  }
`;

export const Logo = styled.img`
  width: 100px; /* Largura da imagem da logo */

  @media (max-width: 768px) {
    width: 80px; /* Reduz a largura da logo para telas menores */
  }
`;
export const VerticalBar = styled.div`
  width: 0.1rem; /* largura da barra */
  height: 1.2rem;
  background-color: #fff; /* cor de fundo da barra */
  margin-top: auto;
`;

export const Nav = styled.nav`
  flex-grow: 1;
  text-align: center;
  font-size: large;
  display: flex;
  justify-content: center; /* Centraliza os itens */

  @media (max-width: 768px) {
    flex: 1; /* Permite que os itens do menu sejam empilhados em telas menores */
    gap: 0.8em;
    font-size: small;
  }
`;

export const NavItem = styled.a`
  margin: 0 2rem;
  text-decoration: none;
  color: #fff; /* Cor dos links */
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #ef9c3b; /* Cor dos links ao passar o mouse */
    text-shadow: #ef9c3b;
  }

  @media (max-width: 768px) {
    margin: 0; /* Remove a margem entre os itens do menu */
  }
`;

export const InstagramButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white; /* Cor do ícone do Instagram */
  transition: color 0.3s ease;
  font-weight: 600;
  font-size: large;
  background-color: #8a3ab9;
  padding: 1%;
  border-radius: 6%;

  @media (max-width: 768px) {
    display: flex; /* Garante que o botão do Instagram seja exibido em telas menores */
    margin-left: auto; /* Move o botão do Instagram para a direita */
    width: 20%;
    font-size: small;
  }
`;

export const InstagramIcon = styled.img``;
