import React, { useEffect, useState } from "react";
import {
  FormField,
  SectionContent,
  SectionContainer,
  SectionTitle,
  Form,
  PopupContainer,
  ErrorPopupContainer,
} from "../styles/components/Section2Styles";

const Section2 = ({ onSubmitData }) => {
  const [nome, setNome] = useState("");
  const [rede, setRede] = useState("");
  const [faturamento, setFaturamento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const sendData = () => {
    if (nome && telefone) {
      setShowPopup(true);
      const dataToSave = {
        nome,
        rede,
        faturamento,
        telefone,
      };
      onSubmitData(dataToSave);
      setNome("");
      setRede("");
      setFaturamento("");
      setTelefone("");
    } else {
      setShowErrorPopup(true);
    }
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 4000); // Oculta o popup após 4 segundos

      return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
    }
  }, [showPopup]);

  useEffect(() => {
    if (showErrorPopup) {
      const timer = setTimeout(() => {
        setShowErrorPopup(false);
      }, 4000); // Oculta o popup de erro após 4 segundos

      return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
    }
  }, [showErrorPopup]);

  return (
    <SectionContainer id="section2">
      <SectionTitle>
        Receba uma ligação de um dos nossos especialistas
      </SectionTitle>
      <SectionContent>
        Preencha o formulário abaixo e um de nossos especialistas entrará em
        contato.
      </SectionContent>
      <Form>
        <FormField>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Qual o seu nome?"
            maxLength={40}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </FormField>
        <FormField>
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="Seu telefone"
            maxLength={15}
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
          />
        </FormField>
        <FormField>
          <input
            type="text"
            id="instagram"
            name="rede"
            placeholder="Qual Instagram da empresa?"
            maxLength={100}
            value={rede}
            onChange={(e) => setRede(e.target.value)}
          />
        </FormField>
        <FormField>
          <select
            id="revenue"
            name="faturamento"
            value={faturamento}
            onChange={(e) => setFaturamento(e.target.value)}
          >
            <option value="" disabled hidden>
              Qual o faturamento mensal da sua empresa?
            </option>
            <option value="até 20 mil">Até 20 mil</option>
            <option value="De R$20.000,00 a R$50.000,00 reais">
              De R$20.000,00 a R$50.000,00 reais
            </option>
            <option value="De R$50.000,00 a R$80.000,00 reais">
              De R$50.000,00 a R$80.000,00 reais
            </option>
            <option value="De R$80.000,00 a R$100.000,00 reais">
              De R$80.000,00 a R$100.000,00 reais
            </option>
            <option value="Mais de R$100.000,00 reais">
              Mais de R$100.000,00 reais
            </option>
          </select>
        </FormField>
        <FormField>
          <button type="button" onClick={sendData}>
            Enviar
          </button>
        </FormField>
      </Form>
      {showPopup && (
        <PopupContainer>
          Obrigado! Nossa equipe entrará em contato em breve! :)
        </PopupContainer>
      )}
      {showErrorPopup && (
        <ErrorPopupContainer>
          Por favor, verifique as informações inseridas! :)
        </ErrorPopupContainer>
      )}
    </SectionContainer>
  );
};

export default Section2;
