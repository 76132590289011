// Section4.js

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CardTitle,
  CardContent,
  Button,
  SectionContainer,
  SectionTitle,
  SectionContent,
  Card,
  CardIcon,
  StyledSlider,
} from "../styles/components/Section4Styles";
import {
  FaAd,
  FaUsers,
  FaClipboardCheck,
  FaLaptopCode,
  FaNewspaper,
  FaChartLine,
} from "react-icons/fa";

const Section4 = () => {
  // Função para rolar até a Section2
  const scrollToSection2 = () => {
    const section2 = document.getElementById("section2");
    if (section2) {
      const offset = 55;
      window.scrollTo({
        top: section2.offsetTop - offset,
        behavior: "smooth", // Para uma rolagem suave
      });
    }
  };

  // Configurações do carrossel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Ativa o autoplay
    autoplaySpeed: 3000, // Define o intervalo de tempo em milissegundos (por exemplo, 3000ms = 3 segundos)
  };
  

  // Verifica se a largura da tela é menor ou igual a 768px
  const isMobile = window.innerWidth <= 768;

  return (
    <SectionContainer id="section4">
      <SectionTitle>
        Conheça todos os serviços que oferecemos para que sua empresa tenha mais resultados:
      </SectionTitle>
      {isMobile ? (
        <StyledSlider {...settings}>
          <Card>
            <CardIcon>
              <FaAd />
            </CardIcon>
            <CardTitle>Tráfego pago</CardTitle>
            <CardContent>
              Gestão de anúncios online no Facebook, Instagram e Google Ads. Atraia clientes qualificados. Gerar oportunidades de vendas diariamente.
            </CardContent>
          </Card>
          <Card>
          <CardIcon>
            <FaUsers />
          </CardIcon>
          <CardTitle>Estruturação do comercial</CardTitle>
          <CardContent>
            Iremos treinar os seus vendedores para que consigam transformar oportunidades em vendas.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaClipboardCheck />
          </CardIcon>
          <CardTitle>Implementação de CRM</CardTitle>
          <CardContent>
            Gerencie o relacionamento com os clientes de forma otimizada e mantenha a conexão com os seus clientes.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaLaptopCode />
          </CardIcon>
          <CardTitle>Criação de Sites</CardTitle>
          <CardContent>
            Fazemos sites profissionais e personalizados para atender as necessidades da sua empresa. Utilizamos as últimas tecnologias do mercado e criaremos um designer exclusivo que irá encantar clientes e potencializar suas vendas.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaNewspaper />
          </CardIcon>
          <CardTitle>Assessoria de conteúdo</CardTitle>
          <CardContent>
            Planejamento estratégico e acompanhamento de postagens, incluindo envio de tendências.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaChartLine />
          </CardIcon>
          <CardTitle>Relatórios em tempo real</CardTitle>
          <CardContent>
            Com a DRS3 você tem acesso em tempo real aos relatórios das suas campanhas e retorno sobre o seu investimento.
          </CardContent>
        </Card>
        </StyledSlider>
      ) : (
        <SectionContent>
          <Card>
            <CardIcon>
              <FaAd />
            </CardIcon>
            <CardTitle>Tráfego pago</CardTitle>
            <CardContent>
              Gestão de anúncios online no Facebook, Instagram e Google Ads. Atraia clientes qualificados. Gerar oportunidades de vendas diariamente.
            </CardContent>
          </Card>
          <Card>
          <CardIcon>
            <FaUsers />
          </CardIcon>
          <CardTitle>Estruturação do comercial</CardTitle>
          <CardContent>
            Iremos treinar os seus vendedores para que consigam transformar oportunidades em vendas.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaClipboardCheck />
          </CardIcon>
          <CardTitle>Implementação de CRM</CardTitle>
          <CardContent>
            Gerencie o relacionamento com os clientes de forma otimizada e mantenha a conexão com os seus clientes.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaLaptopCode />
          </CardIcon>
          <CardTitle>Criação de Sites</CardTitle>
          <CardContent>
            Fazemos sites profissionais e personalizados para atender as necessidades da sua empresa. Utilizamos as últimas tecnologias do mercado e criaremos um designer exclusivo que irá encantar clientes e potencializar suas vendas.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaNewspaper />
          </CardIcon>
          <CardTitle>Assessoria de conteúdo</CardTitle>
          <CardContent>
            Planejamento estratégico e acompanhamento de postagens, incluindo envio de tendências.
          </CardContent>
        </Card>
        <Card>
          <CardIcon>
            <FaChartLine />
          </CardIcon>
          <CardTitle>Relatórios em tempo real</CardTitle>
          <CardContent>
            Com a DRS3 você tem acesso em tempo real aos relatórios das suas campanhas e retorno sobre o seu investimento.
          </CardContent>
        </Card>
        </SectionContent>
      )}
      <Button onClick={scrollToSection2}>Agendar uma reunião</Button>
    </SectionContainer>
  );
};

export default Section4;
