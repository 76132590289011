import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: linear-gradient(to top, #000, #1a1a1a);
  color: #fff;
  padding: 20px;
  text-align: center;
  padding-top: 2%;
`;

export const FooterLogo = styled.img`
  max-width: 100px;
  margin-bottom: 10px;
`;

export const FooterText = styled.p`
  font-weight: bold;

  > a {
    font-size: 0.9em;
    font-weight: 200;
    color: #fff; /* Define a cor do link como preto */
    text-decoration: none; /* Remove o sublinhado */
  }
`;


export const IconContainer = styled.div`
  margin-top: 10px;
`;  
