import React, { useEffect } from "react";
import {
  PoliticasContainer,
  PoliticasParagraph,
  PoliticasTitle,
  PoliticasSubTitle,
  GoBack,
} from "../styles/pages/PoliticasStyles";
import Footer from "../components/Footer";
import { FaArrowLeft } from "react-icons/fa";

const Politicas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PoliticasContainer>
        <GoBack>
          <a href="/">
            <FaArrowLeft />
            <item> Voltar a página principal</item>
          </a>
        </GoBack>
        <PoliticasTitle>Política de Privacidade</PoliticasTitle>
        <PoliticasParagraph>
          O objetivo desta Política de Privacidade é esclarecer quais dados são
          coletados neste site (www.drs3aceleradoradigital.com), que pertence à
          empresa DRS3 Aceleradora Digital, inscrita no CNPJ 44.043.457/0001-59.
          Além de esclarecer como os dados são coletados, esta Política de
          Privacidade também informa como eles são utilizados, compartilhados e
          armazenados por meio do nosso site e dos respectivos serviços. Por
          isso, pedimos que leia atentamente esta Política para se certificar
          que estamos comprometidos com a segurança dos seus dados. É importante
          dizer que a aceitação da nossa Política de Privacidade é feita quando
          você se cadastra nesse site para utilizar nossos serviços, incluindo
          os gratuitos. Dessa forma, entendemos que você está ciente e de acordo
          com a forma que utilizaremos os seus dados coletados.
        </PoliticasParagraph>
        <PoliticasSubTitle>
          QUAIS SÃO AS FONTES DE COLETA DE DADOS E QUAIS DADOS PESSOAIS SÃO
          COLETADOS NESTE SITE?
        </PoliticasSubTitle>
        <item>Formulários de contato</item>Sempre que você preencher os
        formulários contidos neste site – como os formulários de contato, os
        seus dados pessoais que são pedidos para serem preenchidos são coletados
        e armazenados por nós com o objetivo de prestarmos o devido atendimento
        solicitado. É importante dizer que quando você nos fornece seus dados
        pessoais, a nossa equipe da DRS3 Aceleradora Digital pode entrar em
        contato com você via telefone, email ou WhatsApp, seja para fornecer
        algum atendimento, realizar alguma pesquisa, informar alguma novidade,
        promoção, indicar algum produto e serviço que seja do seu interesse, ou,
        ainda, para cumprir obrigações legais. Você tem o direito de solicitar a
        exclusão ou alteração dos seus dados a qualquer momento, bem como o fim
        do recebimento das nossas mensagens. A DRS3 Aceleradora Digital se
        reserva no direito de monitorar todo o site para assegurar que o
        comportamento de navegação dos usuários esteja de acordo com sua
        Política de Privacidade. Por isso, também nos reservamos o direito de
        excluir determinado usuário caso esta Política não esteja sendo
        respeitada. A exclusão dos dados pessoais será realizada a qualquer
        tempo a pedido da parte concedente ou ao atender sua finalidade, no
        período contratual assinado junto ao tratador/contratada.
        <item>Sobre o acesso aos seus dados pessoais</item>
        Apenas os funcionários autorizados da DRS3 Aceleradora Digital poderão
        ter acesso aos seus dados pessoais. Eventualmente, caso a inclusão das
        suas informações se dê em razão da criação de parcerias, os parceiros
        autorizados também terão acesso a esses dados. Nenhum dado pessoal
        poderá ser divulgado publicamente sem a sua expressa autorização. A DRS3
        Aceleradora Digital empreenderá todos os esforços razoáveis de mercado
        para garantir que os nossos sistemas estejam seguros. Nós manteremos os
        seus dados pessoais e informações somente até quando eles forem
        necessários ou relevantes para as finalidades descritas nesta Política
        de Privacidade, além de casos pré-determinados por lei. É importante
        dizer que mesmo empreendendo todos os esforços para resguardar os seus
        dados, não temos como garantir completamente que eles estarão livres de
        acessos não autorizados e compartilhamentos indevidos. Caso algum
        incidente aconteça, você será devidamente informado sobre o que estamos
        fazendo para corrigir o problema.
        <item>Quais são os seus direitos sobre os dados coletados</item> Acesso
        aos dados: você pode ter acesso aos seus dados a qualquer momento; -
        Sair da lista de contato: você pode entrar em contato conosco para sair
        da nossa lista de e-mail ou contatos via telefone e outros meios de
        comunicação; - Alteração dos dados: a qualquer momento você pode
        solicitar para atualizarmos ou corrigirmos qualquer informação pessoal
        fornecida a nós; - Informações sobre os dados: você pode tirar dúvidas
        ou pedir esclarecimentos sobre os seus dados fornecidos a nós a qualquer
        momento; - Eliminação dos dados: a qualquer momento você pode pedir para
        excluirmos os seus dados do nosso sistema. É importante dizer que
        eventualmente poderemos ter que guardar alguma informação para mantermos
        em nosso registro. Caso queira exercer qualquer um dos direitos
        mencionados, entre em contato conosco. Para a sua proteção, apenas
        poderemos atender a solicitações feitas com o mesmo e-mail associado aos
        seus dados, ou, ainda, por meio da verificação da sua identidade.
        <item>Sobre o consentimento desta Política de Privacidade</item>A
        aceitação desta Política de Privacidade é feita quando você se cadastra
        em nosso site para usufruir de um dos serviços que mencionamos, mesmo
        que de forma gratuita. Isso indica que você está ciente e em total
        acordo com a maneira que utilizaremos os seus dados e informações
        coletadas. Caso não concorde com esta Política de Privacidade, pedimos
        que não continue o seu procedimento de registro e, portanto, não utilize
        nossos serviços. Se você não optar por fornecer os seus dados pessoais
        necessários para utilizar nossos produtos ou a prestação dos nossos
        serviços, não poderemos fornecê-los a você. No entanto, gostaríamos de
        saber o motivo da discordância para que possamos melhorar os nossos
        serviços. Mudanças na Política de Privacidade: Esta Política de
        Privacidade pode passar por atualizações. Por isso, recomendamos que
        você visite esta página periodicamente para verificar possíveis
        mudanças. Caso sejam feitas alterações relevantes que demandem novas
        autorizações suas, publicaremos uma nova Política de Privacidade. Antes
        de usar as informações para outros fins que não os definidos nesta
        Política de Privacidade, nós solicitaremos a sua autorização.
        <item>Lei Aplicável</item>
        Este documento é regido e deve ser interpretado de acordo com as leis da
        República Federativa do Brasil. Fica eleito o Foro da Comarca de Palmas
        - TO, como o competente para dirimir quaisquer questões porventura
        oriundas do presente documento, com expressa renúncia a qualquer outro,
        por mais privilegiado que seja.
        <GoBack>
          <a href="/">
            <p>
              <FaArrowLeft />
              <item> Voltar a página principal</item>
            </p>
          </a>
        </GoBack>
      </PoliticasContainer>
      <Footer />
    </>
  );
};

export default Politicas;
