import React from "react";
import logoImg from "../assets/logoDrs3.png";
import {
  HeaderContainer,
  InstagramButton,
  Logo,
  Nav,
  NavItem,
  VerticalBar,
} from "../styles/components/HeaderStyles";

const Header = () => {
  // Função para rolar até a Section2
  const scrollToSection4 = () => {
    const section4 = document.getElementById("section4");
    if (section4) {
      const offset = 55;
      window.scrollTo({
        top: section4.offsetTop - offset,
        behavior: "smooth" // Para uma rolagem suave
      });
    }
  };
  return (
    <HeaderContainer>
      <a href={window.location.href}>
      <Logo src={logoImg} alt="Logo"/>
      </a>
      <Nav>
        <NavItem href="#" onClick={scrollToSection4}>O que fazemos</NavItem>
        <VerticalBar/>
        <NavItem href="https://wa.me/6399285109" target="_blank">Contato</NavItem>
      </Nav>
      <InstagramButton href="https://www.instagram.com/drs3.aceleradora/" target="_blank">
        Instagram
      </InstagramButton>
    </HeaderContainer>
  );
};

export default Header;
