import styled from "styled-components";

export const PoliticasContainer = styled.div`
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 4rem;
  text-align: justify;

  > item {
    display: block; /* Para iniciar em uma nova linha */
    font-weight: bold;
    color: #fc9404;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const PoliticasTitle = styled.h1`
  color: #fc9404;
  padding-bottom: 1rem;
`;

export const PoliticasSubTitle = styled.h1`
  color: #fc9404;
  padding-bottom: 0.6rem;
  font-size: 1.2rem;
`;

export const PoliticasParagraph = styled.p`
  margin-bottom: 20px;
`;

export const GoBack = styled.p`
  margin-bottom: 2em;
  margin-top: 1em;
  > a {
    color: #fff; /* Define a cor do link como preto */
    text-decoration: none; /* Remove o sublinhado */
  }
`;
