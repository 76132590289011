import React from "react";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Header from "../components/Header";
import Section1 from "../components/Section1";
import Section4 from "../components/Section4";
import Footer from "../components/Footer";
import { addDoc } from "firebase/firestore";
import Section5 from "../components/Section5";

const Home = ({ formCollectionRef }) => {
  const setData = async (data) => {
    try {
      const formulario = await addDoc(formCollectionRef, data);
      console.log(formulario);
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };

  return (
    <div>
      <Header />
      <Section1 />
      <Section2 onSubmitData={setData} />
      <Section3 />
      <Section4/>
      <Section5/>
      <Footer/>
    </div>
  );
};

export default Home;
