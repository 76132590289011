import styled from 'styled-components';

// Styled component para a seção
export const SectionContainer = styled.section`
  padding: 50px 0;
  background-color: #1a1a1a;
  text-align: center;
`;

// Styled component para o título da seção
export const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fc9404;
`;

// Styled component para o conteúdo da seção
export const SectionContent = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #fff;
`;

// Styled component para o formulário
export const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Styled component para os campos do formulário
export const FormField = styled.div`
  margin-bottom: 20px;

  input[type="text"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  select {
    appearance: none;
  }

  button[type="button"] {
    width: 100%;
    padding: 10px;
    background-color: #fc9404;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      transform: scale(1.05); 
    }
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #f0fff0; /* Verde claro para o fundo */
  color: #006400; /* Verde escuro para o texto */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

export const ErrorPopupContainer = styled(PopupContainer)`
  background-color: #ffcccc; /* Cor de fundo para o caso de erro */
  color: #ff0000; /* Cor do texto para o caso de erro */
`;  