// Section1.js

import React from "react";
import {
  LeftContent,
  MainText,
  RightImage,
  SecondaryText,
  SectionContainer,
} from "../styles/components/Section1Styles";
import imgPerfil from "../assets/perfilSite1.png"

const Section1 = () => {
  return (
    <SectionContainer id="section1">
      <LeftContent>
        <MainText>
          <label>Acelere</label> o crescimento da sua empresa no digital!
        </MainText>

        <SecondaryText>
          Receba leads qualificados diariamente através de estratégias digitais
          de quem faz marketing para <label> + de 50 empresas.</label>
        </SecondaryText>
      </LeftContent>
      <RightImage src={imgPerfil} alt="Imagem relacionada ao conteúdo" />
    </SectionContainer>
  );
};

export default Section1;
