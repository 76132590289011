// Section1Styles.js

import styled, { keyframes } from "styled-components";

export const SectionContainer = styled.section`
  display: flex;
  align-items: flex-end; /* Alinha os itens na parte inferior do container */
  justify-content: space-between;
  padding: 0px 20px;
  background: radial-gradient(circle, #1a1a1a, #000); /* Degradê circular */

  @media (max-width: 768px) {
    flex-direction: column; /* Altera a direção do flex para empilhamento em telas menores */
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const LeftContent = styled.div`
  flex: 1;
  max-width: 50%;
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 20%;
  margin-left: 4%;
  z-index: 3;

  @media (max-width: 768px) {
    max-width: 100%; /* Define a largura máxima como 100% para telas menores */
    margin-left: 0; /* Remove a margem esquerda em telas menores */
    margin-bottom: 0px; /* Adiciona uma margem inferior em telas menores */
  }
`;

const moveAnimation = keyframes`
   0% {
    transform: translateY(100%) rotate(0deg);
    opacity: 0;
  }
  60% {
    transform: translateY(0) rotate(-20deg); /* Inclina para cima */
  }
  80% {
    transform: translateY(0) rotate(-2deg); /* Inclina para baixo */
  }
  100% {
    transform: translateY(0) rotate(0deg); /* Retorna ao alinhamento normal */
    opacity: 1;
  }
`;

// Styled component para o texto principal
export const MainText = styled.h1`
  font-size: 3.14rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;

  /* Aplica a animação somente para o texto dentro da tag <label> */
  > label {
    color: #fc9404;
    display: inline-block; /* Garante que a animação funcione corretamente */
    animation: ${moveAnimation} 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.7) 1
      forwards; /* Aplica a animação com um timing mais impactante e executa apenas uma vez */

    /* Efeito de hover para destacar ainda mais o texto */
    &:hover {
      text-shadow: 0 0 14px rgba(252, 148, 4, 0.5); /* Adiciona uma sombra ao texto */
    }
  }

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

export const SecondaryText = styled.p`
  font-size: 1.15rem;
  color: #fff;
  margin-top: 1.5em;
  > label {
    color: #fc9404;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

export const RightImage = styled.img`
  flex: 1;
  max-width: 38%;
  height: auto; /* Adiciona altura automática para evitar esticamento vertical */
  z-index: 1;
  margin-top: auto; /* Move a imagem para a parte inferior do contêiner */
  border-radius: 0.6rem;
  @media (max-width: 768px) {
    width: 110%;
    margin-right: 25%; /* Remove a margem direita em telas menores */
    max-width: 45%;
    padding-bottom: 0;
  }
`;
