import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardTitle,
  SectionContainer,
  SectionContent,
  SectionSubTitle,
  SectionTitle,
} from "../styles/components/Section3Styles";
const Section3 = () => {
  // Função para rolar até a Section2
  const scrollToSection2 = () => {
    const section2 = document.getElementById("section2");
    if (section2) {
      const offset = 50;
      window.scrollTo({
        top: section2.offsetTop - offset,
        behavior: "smooth" // Para uma rolagem suave
      });
    }
  };
  return (
    <SectionContainer id="#section3">
      <SectionTitle>
        Como vamos gerar resultados para a sua empresa?
      </SectionTitle>
      <SectionSubTitle>
        Através da metodologia <label>3C’S</label>
      </SectionSubTitle>
      <SectionContent>
        <Card>
          <CardTitle>Comunicação</CardTitle>
          <CardContent>
            Nessa etapa vamos mapear o seu cliente ideal, estruturar o
            posicionamento da sua empresa e deixar o seu perfil atrativo.
          </CardContent>
        </Card>
        <Card>
          <CardTitle>Conexão</CardTitle>
          <CardContent>
            Nessa segunda fase iremos criar um funil de marketing personalizado
            para a sua empresa e atrair leads qualificados para comprar de você.
          </CardContent>
        </Card>
        <Card>
          <CardTitle>Conversão</CardTitle>
          <CardContent>
            Nessa última etapa iremos implementar um processo de vendas e
            estruturar o seu atendimento para que você transforme pessoas
            interessadas em clientes compradores.
          </CardContent>
        </Card>
      </SectionContent>
      <Button onClick={scrollToSection2}>Agendar uma reunião</Button>
    </SectionContainer>
  );
};

export default Section3;
