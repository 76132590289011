import styled from 'styled-components';

export const SectionContainer = styled.section`
  padding: 20px 46px;
  background-color: #1a1a1a;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const Question = styled.div`
  margin-bottom: 40px;
`;

export const QuestionTitle = styled.h3`
  font-size: 20px;
  color: #fc9404;
`;

export const QuestionText = styled.p`
  font-size: 14px;
  margin-top: 10px;
`;

export const Icon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
`;