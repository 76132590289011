import React from "react";
import "./App.css";
import Home from "./pages/Home";
import GlobalStyles from "./styles/GlobalStyles";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Politicas from "./pages/Politicas";
import { collection, getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDhQployXCR531PpTj6iaY-PfIvkQCMUxY",
  authDomain: "drs3-teste.firebaseapp.com",
  projectId: "drs3-teste",
});

const db = getFirestore(firebaseApp);
const formCollectionRef = collection(db, "formulario");

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyles />
        <Routes>
          <Route
            path="/"
            element={<Home formCollectionRef={formCollectionRef} />}
          />
          <Route path="/politicas" element={<Politicas />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;