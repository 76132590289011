import Slider from "react-slick";
import styled from "styled-components";
export const SectionContainer = styled.section`
  padding: 50px 0;
  background-color: #454545; 
  text-align: center;
  color: #fff;

  @media (max-width: 768px) {
    padding: 50px 30px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 20px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
`;



export const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const CardContent = styled.p`
  font-size: 1.1rem;
`;

export const CardIcon = styled.div`
  font-size: 2rem; /* Tamanho do ícone */
  color: #fc9404; /* Cor do ícone */
  margin-bottom: 10px; /* Espaçamento inferior */
`;
export const Button = styled.button`
margin-top: 2rem;
  padding: 10px 20px;
  background-color: #fc9404;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adiciona uma transição suave para a cor de fundo e transformação (escala) */
  width: 250px;
  &:hover {
    background-color: #ffaa22; /* Altera a cor de fundo ao passar o mouse */
    transform: scale(1.07); /* Aumenta a escala em 7% */
  }
`;

export const Card = styled.div`
  width: 310px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff; /* Fundo branco */
  color: #1a1a1a; /* Azul escuro */
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  height: 17.3rem;

    @media (max-width: 768px) {
    margin-right: 15px; /* Adiciona margem à direita para separar os cards */
  }
  
  @media (min-width: 769px) {
  &:hover {
    transform: scale(1.05);
    background-color: #fc9404; /* Destaque em laranja */
    color: #fff; /* Texto branco */
    
    /* Altera a cor do ícone para azul quando o mouse passa sobre o card */
    ${CardIcon} {
      color: #1a1a1a; /* Azul escuro */
    }
  }
}
`;

export const StyledSlider = styled(Slider)`
  .slick-dots li button:before {
    color: white; // Cor branca para os indicadores inativos
  }
  .slick-dots li.slick-active button:before {
    color: white; // Cor branca para o indicador ativo
  }
`;