import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import {
  FooterContainer,
  FooterLogo,
  FooterText,
  IconContainer,
} from "../styles/components/Footer";
import logoImg from "../assets/logoDrs3.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLogo src={logoImg} alt="DRS3 Logo" />
      <FooterText>
        {" "}
        2024 © DRS3 Aceleradora Digital.<p>Todos os direitos reservados. </p>
        <Link to="/politicas">Políticas de privacidade</Link>
      </FooterText>
      <IconContainer>
        <a
          href="https://wa.me/6399285109"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", marginRight: "10px" }}
        >
          <FaWhatsapp size={20} />
        </a>
        |
        <a
          href="https://www.instagram.com/drs3.aceleradora/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", marginLeft: "10px" }}
        >
          <FaInstagram size={20} />
        </a>
      </IconContainer>
    </FooterContainer>
  );
};

export default Footer;
